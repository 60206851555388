<template>
    <div id="footer">
        <div>
            <div>
                <ul class="footer_list">
                    <li>PP-NUB-ES-0969-1 05-2024</li>
                    <li> <a href="https://www.bayer.com/es/es/espana-condiciones-generales-de-uso" target="_blank">Condiciones de uso</a></li>
                    <li> <a href="https://dps.bayer.com/ph/es/es/data_privacy_statement" target="_blank">Política de privacidad</a></li>
                    <li> <a href="https://www.bayer.com/es/es/espana-pie-de-imprenta" target="_blank">Pie de imprenta</a></li>
                    <li> <a href="https://www.bayer.com/es/es/espana-contacto" target="_blank">FARMACOVIGILANCIA</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "FooterMenu",
    data() {
        return {
            loading: true,
        };
    },
    computed: {
    },

    methods: {


    },
    created() {
    },
};
</script>

<style scoped>
#footer {
    padding-top: 10px;
    color: #c1c1c1;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
}

.footer_list {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 10px;
    justify-content: center;
}

.footer_list a {
    text-decoration: none;
    color: inherit;
}
</style>